<template>
    <div class="d-flex align-items-center justify-content-center">
        
        <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" :style="{width: progress+'%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <!-- Start -->
        <div v-if="step == 0"  class="container intro text-center">
            <div class="mb-5 mt-4">
                <img src="./assets/logo.png" class="logo-intro">
            </div>
            <p class="lead mb-5">
                Partecipa al test di orientamento online e scopri il tuo talento.<br>Rispondi alle domande per trovare il corso di laurea più adatto a te.
            </p>
            <button class="btn btn-outline-dark rounded-0 btn-lg" @click="step = 1">Inizia adesso!</button>
        </div>

        <!-- Dati -->
        <div v-if="step == 1" class="container">
            <div class="mb-5 mt-4 mt-3 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5 text-center">Ciao, prima di iniziare abbiamo bisogno di alcune informazioni</h1>
            <form @submit.prevent="submitStep()" class="card p-5 border rounded-4">
                <div class="row">
                    <div class="col-12 mb-3">
                        <input type="text" class="form-control form-control-lg" placeholder="Nome *" v-model="form.first_name" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="text" class="form-control form-control-lg" placeholder="Cognome *" v-model="form.last_name" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="email" class="form-control form-control-lg" placeholder="E-Mail *" v-model="form.email" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="number" class="form-control form-control-lg" placeholder="Telefono *" v-model="form.telephone" required>
                    </div>
                    <div class="col-12 mb-1 small">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="1" v-model="form.privacy" required>
                            <label class="form-check-label">
                                Autorizzo al trattamento dei dati personali per finalità connesse allo svolgimento delle attività istituzionali (obbligatorio) * 
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mb-3 small">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="1" v-model="form.marketing">
                            <label class="form-check-label">
                                Autorizzo al trattamento dei dati per invio di materiale informativo 
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-lg" :disabled="!startTest">Avvia il Test</button>
                </div>
            </form>
        </div>
        
        <!-- Domanda 1 -->
        <div v-if="step == 2" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">1 - Quanto tempo trascorri solitamente leggendo libri per piacere?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a1" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a1" :keyFor="i" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a1">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 2 -->
        <div v-if="step == 3" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">2 - Qual è il tuo genere letterario preferito?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a2" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a2" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a2">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 3 -->
        <div v-if="step == 4" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">3 - Cosa ti affascina maggiormente nella lettura?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a3" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a3" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a3">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 4 -->
        <div v-if="step == 5" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">4 - Ti piacerebbe lavorare in un settore legato alla pubblicazione e alla promozione dei libri?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a4" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a4" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a4">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 5 -->
        <div v-if="step == 6" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">5 - Hai mai considerato di scrivere un libro o di lavorare come redattore/editor?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a5" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a5" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a5">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 6 -->
        <div v-if="step == 7" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">6 - Quale di queste attività ti attira di più?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a6" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a6" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a6">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 7 -->
        <div v-if="step == 8" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">7 - Qual è il tuo rapporto con le lingue straniere?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a7" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a7" />
                </div>
            </div>
            <div class=" p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a7">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 8 -->
        <div v-if="step == 9" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">8 - Ti piace analizzare e interpretare testi letterari?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a8" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a8" />
                </div>
            </div>
            <div class=" p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a8">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 9 -->
        <div v-if="step == 10" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">9 - Qual è il tuo obiettivo principale dopo la laurea?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a9" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a9" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="submitForm" :disabled="!form.a9">Vai al Risultato</button>
            </div>
        </div>

        <!-- Risultato -->
        <div v-if="step == 11" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="./assets/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">Area {{ result[result_area].area }}</h1>
            <div class="row mb-5">
                <div class="col">
                    {{ result[result_area].description }}
                </div>
            </div>
            <h4 class="mb-5">Scopri i Corsi di Laurea</h4>
            <div class="row justify-content-center ">
                <div class="col-12 col-lg-4 mb-5 results-card" v-for="(course, i) in courses" :key="i">
                    <div class="card-bg rounded">
                        <div class="p-3 text-white">
                            <h5 class="d-block font-weight-bold m-0">{{ course.title }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button class="btn btn-primary btn-lg" @click="refreshPage()">Ricomincia il test!</button>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

    import RadioBox from '@/components/RadioBox'
    import AOS from 'aos'; 
    import 'aos/dist/aos.css';

    export default {
        components: {
            RadioBox
        },
        data() {
            return {
                step: 0,
                form: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    telephone: null,
                    privacy: 0,
                    marketing: 0,
                    a1: 0,
                    a2: 0,
                    a3: 0,
                    a4: 0,
                    a5: 0,
                    a6: 0,
                    a7: 0,
                    a8: 0,
                    a9: 0
                },
                questions: {
                    a1: [
                        { d: 'Circa un\'ora al giorno', v: 2 },
                        { d: 'Tra 2 e 3 ore a settimana', v: 9 },
                        { d: 'Dipende dalla mia agenda, ma cerco di leggere quando posso', v: 3 },
                        { d: 'Ogni volta che ho un momento libero, sono sempre immerso in un libro', v: 4 },
                        { d: 'Solitamente leggo solo durante le vacanze o nei periodi di relax', v: 8 },
                        { d: 'Preferisco ascoltare audiolibri mentre faccio altre attività', v: 7 },
                        { d: 'Cerco di leggere ogni sera prima di andare a letto', v: 5 },
                        { d: 'Leggo principalmente nei fine settimana', v: 1 },
                        { d: 'Trascorro più tempo a leggere durante i mesi invernali', v: 6 },
                    ],
                    a2: [
                        { d: 'Romanzi storici', v: 2 },
                        { d: 'Fantascienza e fantasy', v: 9 },
                        { d: 'Thriller e gialli', v: 3 },
                        { d: 'Saggi e saggistica', v: 4 },
                        { d: 'Poesia e poesia contemporanea', v: 8 },
                        { d: 'Letteratura classica', v: 7 },
                        { d: 'Narrativa contemporanea', v: 5 },
                        { d: 'Biografie e memorie', v: 1 },
                        { d: 'Libri di viaggio e avventura', v: 6 },
                    ],
                    a3: [
                        { d: 'Esplorare mondi immaginari e fantasiosi', v: 2 },
                        { d: 'Analizzare la psicologia dei personaggi', v: 9 },
                        { d: 'Approfondire concetti filosofici e morali', v: 3 },
                        { d: 'Imparare nuove cose e arricchire la mia conoscenza', v: 4 },
                        { d: 'Vivere emozioni intense attraverso le storie', v: 8 },
                        { d: 'Scoprire nuove prospettive e punti di vista', v: 7 },
                        { d: 'Sviluppare le mie capacità di pensiero critico', v: 5 },
                        { d: 'Fuggire dalla realtà per un po\'', v: 1 },
                        { d: 'Connettermi con gli autori e le loro esperienze di vita', v: 6 },
                    ],
                    a4: [
                        { d: 'Assolutamente sì, sarebbe un sogno diventato realtà', v: 2 },
                        { d: 'Potrebbe essere interessante, dipende dalle opportunità', v: 9 },
                        { d: 'Preferirei esplorare altre opzioni di carriera', v: 3 },
                        { d: 'No, non credo che sia il campo giusto per me', v: 4 },
                        { d: 'Sì, ma solo se ci fosse spazio per la creatività e l\'innovazione', v: 8 },
                        { d: 'Potrebbe essere una possibilità, ma non è la mia priorità', v: 7 },
                        { d: 'Preferirei lavorare direttamente con gli autori piuttosto che nell\'ambito della pubblicazione', v: 5 },
                        { d: 'Sono aperto/a a esplorare diverse opportunità di lavoro', v: 1 },
                        { d: 'No, preferirei rimanere un lettore appassionato senza coinvolgermi professionalmente', v: 6 },
                    ],
                    a5: [
                        { d: 'Sì, è qualcosa che ho sempre sognato di fare', v: 2 },
                        { d: 'Forse, ma non sono sicuro/a di avere le competenze necessarie', v: 9 },
                        { d: 'No, non ho mai pensato seriamente a questa possibilità', v: 3 },
                        { d: 'Sì, ma preferirei concentrarmi su altri progetti creativi', v: 4 },
                        { d: 'Potrebbe essere un\'opzione futura, ma al momento non ho tempo per dedicarmici', v: 8 },
                        { d: 'No, non mi considero abbastanza creativo/a per scrivere un libro', v: 7 },
                        { d: 'Lavorare come editor potrebbe essere interessante, ma preferirei concentrarmi su altri settori', v: 5 },
                        { d: 'Ho avuto qualche idea per un libro, ma non ho mai avuto il coraggio di iniziare a scrivere seriamente', v: 1 },
                        { d: 'No, preferisco godermi la lettura dei libri scritti da altri', v: 6 },
                    ],
                    a6: [
                        { d: 'Organizzare eventi letterari e culturali', v: 2 },
                        { d: 'Scrivere articoli e recensioni su libri', v: 9 },
                        { d: 'Condurre ricerche accademiche sulla letteratura', v: 3 },
                        { d: 'Lavorare in una libreria o in una casa editrice', v: 4 },
                        { d: 'Creare contenuti digitali e podcast sulla letteratura', v: 8 },
                        { d: 'Partecipare a club del libro e gruppi di lettura', v: 7 },
                        { d: 'Collaborare con autori per la promozione dei loro libri', v: 5 },
                        { d: 'Curare una rubrica letteraria su un giornale o su un blog', v: 1 },
                        { d: 'Essere un influencer digitale nel campo della letteratura', v: 6 },
                    ],
                    a7: [
                        { d: 'Parlo fluentemente almeno una lingua straniera', v: 2 },
                        { d: 'Posso comunicare in una lingua straniera, ma non sono fluente', v: 9 },
                        { d: 'Ho una conoscenza di base di diverse lingue straniere', v: 3 },
                        { d: 'Sto ancora imparando una nuova lingua straniera', v: 4 },
                        { d: 'Ho studiato lingue straniere in passato, ma non le parlo correntemente', v: 8 },
                        { d: 'Non ho mai studiato lingue straniere', v: 7 },
                        { d: 'Le lingue straniere non sono il mio forte', v: 5 },
                        { d: 'Mi piacerebbe imparare altre lingue straniere in futuro', v: 1 },
                        { d: 'Non ho mai avuto l\'opportunità di imparare altre lingue straniere', v: 6 },
                    ],
                    a8: [
                        { d: 'Sì, mi piace immergermi nei dettagli e scoprire nuovi significati', v: 2 },
                        { d: 'A volte, dipende dal testo e dalla mia disposizione', v: 9 },
                        { d: 'No, preferisco leggere i libri senza analizzarli troppo a fondo', v: 3 },
                        { d: 'Trovo l\'analisi dei testi interessante, ma non è la mia passione principale', v: 4 },
                        { d: 'Mi piace discutere di libri con gli altri, ma non mi piace analizzarli da solo', v: 8 },
                        { d: 'Preferisco concentrarmi sulle emozioni e le esperienze che i libri mi fanno provare', v: 7 },
                        { d: 'Sono più interessato/a alla creatività e all\'immaginazione che all\'analisi critica', v: 5 },
                        { d: 'L\'analisi dei testi letterari può essere noiosa per me', v: 1 },
                        { d: 'Preferisco leggere libri per il puro piacere senza doverli analizzare', v: 6 },
                    ],
                    a9: [
                        { d: 'Lavorare nel mondo dell\'editoria o della promozione culturale', v: 2 },
                        { d: 'Continuare gli studi con una specializzazione in un campo correlato', v: 9 },
                        { d: 'Trovare lavoro in settori non strettamente legati alla letteratura', v: 3 },
                        { d: 'Lavorare come insegnante o educatore nel campo della letteratura', v: 4 },
                        { d: 'Avviare una carriera come scrittore professionista o autore', v: 8 },
                        { d: 'Esplorare diverse opportunità di lavoro per scoprire la mia vera passione', v: 7 },
                        { d: 'Creare un blog o un canale YouTube sulla letteratura', v: 5 },
                        { d: 'Viaggiare e scrivere un libro di viaggio', v: 1 },
                        { d: 'Non ho ancora deciso il mio percorso futuro, sto ancora esplorando le mie opzioni', v: 6 },
                    ],
                },
                result_area: 0,
                result: {
                    1: {
                        area: 'Creativa',
                        description: 'Per te la creatività è più di una passione, è un istinto. La tua mente è sempre pronta a valutare e realizzare nuove idee, non hai paura di sperimentare percorsi inediti e di metterci la faccia. Potresti eccellere in un corso di laurea che riesca a darti gli strumenti giusti per indirizzare la tua straripante creatività verso un obiettivo definito.',
                        slug: 'creativa',
                        degrees: [
                            { title: 'Scienze e Tecnologie delle Arti, dello Spettacolo e del Cinema', university: 'Universitas Mercatorum', slug: 'scienze-e-tecnologie-delle-arti-dello-spettacolo-e-del-cinema'},
                            { title: 'Design del Prodotto e della Moda', university: 'Universitas Mercatorum', slug: 'design-del-prodotto-e-della-moda'},
                            { title: 'Comunicazione e Multimedialità', university: 'Universitas Mercatorum', slug: 'comunicazione-e-multimedialita' }
                        ]
                    },
                    2: {
                        area: 'Sociale',
                        description: 'L’essere umano ha un fascino particolare per te. Sei una persona orientata al dialogo e allo scambio di idee. Potrebbe interessarti comprendere come funziona la mente umana e come le persone interagiscono all’interno della società. Hai l’opportunità di eccellere in un corso di laurea nel quale puoi studiare l’essere umano all’interno del suo habitat naturale: la società',
                        slug: 'sociale',
                        degrees: [
                            { title: 'Scienze Politiche e Relazioni Internazionali', university: 'Universitas Mercatorum', slug: 'scienze-politiche-e-relazioni-internazionali' },
                            { title: 'Sociologia e Innovazione', university: 'Universitas Mercatorum', slug: 'sociologia-e-innovazione' },
                            { title: 'Scienze e tecniche psicologiche', university: 'Universitas Mercatorum', slug: 'scienze-e-tecniche-psicologiche' }
                        ]
                    },
                    3: {
                        area: 'Educativa',
                        description: 'La cultura è al centro dei tuoi interessi. Sei sempre pronto ad approfondire ciò che studi e sei capace di spiegarlo agli altri in modo semplice e comprensibile. Sei consapevole dell’importanza della formazione in un mondo incentrato sulla conoscenza. Potresti eccellere in un corso di laurea che valorizzi la tua capacità di studiare e approfondire tutti i rami della cultura, soprattutto quella umanistica.',
                        slug: 'educativa',
                        degrees: [
                            { title: 'Scienze dell\'Educazione e della Formazione', university: 'Università Pegaso', slug: 'scienze-educazione-e-formazione' },
                            { title: 'Lettere, Sapere Umanistico e Formazione', university: 'Università Pegaso', slug: 'lettere-sapere-umanistico-e-formazione' },
                            { title: 'Filosofia ed Etica', university: 'Università Pegaso', slug: 'filosofia-ed-etica' }
                        ]
                    },
                    4: {
                        area: 'Economica',
                        description: 'Nel tuo mondo, tutto è orientato verso un obiettivo. Sei sempre alla ricerca del modo migliore per far funzionare un progetto e non riesci ad accontentarti di risultati a metà. Vuoi tirare fuori il meglio da ogni cosa e sei capace di costruire piani ben definiti per raggiungere i tuoi obiettivi. Potresti eccellere in un corso di laurea che ti fornisca le competenze adeguate e che dia il giusto spazio alle tue doti di leader.',
                        slug: 'economica',
                        degrees: [
                            { title: 'Gestione di Impresa', university: 'Universitas Mercatorum', slug: 'gestione-di-impresa' },
                            { title: 'Economia Aziendale', university: 'Università Pegaso', slug: 'economia-aziendale' },
                            { title: 'Lingue e Mercati', university: 'Universitas Mercatorum', slug: 'lingue-e-mercati' },
                            { title: 'Ingegneria Gestionale', university: 'Universitas Mercatorum', slug: 'ingegneria-gestionale' }
                        ]
                    },
                    5: {
                        area: 'Giuridica',
                        description: 'La giustizia è al centro dei tuoi valori. Sei sempre pronto a batterti per proteggere i più deboli ma credi che il dialogo e la mediazione siano i modi migliori per trovare la soluzione a tutto. Questo perché sei consapevole che la giustizia sia l’unico vero strumento per assicurare la libertà. Potresti eccellere in un corso di laurea che ti dia le competenze giuridiche adeguate per supportare le tue idee.',
                        slug: 'giuridica',
                        degrees: [
                            { title: 'Giurisprudenza', university: 'Università Pegaso', slug: 'giurisprudenza' },
                            { title: 'Scienze Giuridiche', university: 'Universitas Mercatorum', slug: 'scienze-giuridiche' }
                        ]
                    },
                    6: {
                        area: 'Ingegneristica',
                        description: 'I tuoi progetti sono curati in ogni minimo dettaglio. Sei preciso sia nel definire i tuoi obiettivi sia nel realizzare il processo per raggiungerli. Nessuno è capace come te di notare i particolari e tutti vedono in te un punto di riferimento quando si tratta di costruire piani d’azione articolati e completi. Potresti eccellere in un corso di laurea che ti dia le competenze adeguate per valorizzare la tua straordinaria precisione e cura dei dettagli.',
                        slug: 'ingegneristica',
                        degrees: [
                            { title: 'Ingegneria Civile', university: 'Università Pegaso', slug: 'ingegneria-civile' },
                            { title: 'Ingegneria delle Infrastrutture per una Mobilità Sostenibile', university: 'Universitas Mercatorum', slug: 'ingegneria-delle-infrastrutture-per-una-mobilita-sostenibile' }
                        ]
                    },
                    7: {
                        area: 'Informatica',
                        description: 'Puoi fare tutto con la tecnologia. Il digitale ha un fascino irresistibile per te e ogni giorno scopri nuovi strumenti e nuove possibilità legate al mondo online. Sei molto attento alle novità in ambito digitale e social e tutti si rivolgono a te quando c’è bisogno di sfruttare al massimo le opportunità offerte dalle nuove tecnologie. Potresti eccellere in un corso di laurea che ti fornisca le competenze adeguate per approfondire le tue conoscenze in ambito digitale.',
                        slug: 'informatica',
                        degrees: [
                            { title: 'Ingegneria Informatica', university: 'Universitas Mercatorum', slug: 'ingegneria-informatica' },
                            { title: 'Statistica e Big Data', university: 'Universitas Mercatorum', slug: 'statistica-e-big-data' }
                        ]
                    },
                    8: {
                        area: 'Turistica',
                        description: 'Per te tutto può essere scoperto con le emozioni. Il mondo intero può essere esplorato tramite le sensazioni e ami raccontare storie che sanno affascinare in modi nuovi chi ti ascolta. Il viaggio è l’esperienza umana più importante e sai che si può viaggiare in tanti modi, sfruttando tutti i sensi a disposizione. Potresti eccellere in un corso di laurea che ti fornisce le competenze adatte per stupire gli altri con la tua sensibilità e la tua creatività.',
                        slug: 'turistica',
                        degrees: [
                            { title: 'Gastronomia, Ospitalità e Territori', university: 'Universitas Mercatorum', slug: 'gastronomia-ospitalita-e-territori' },
                            { title: 'Scienze Turistiche', university: 'Università Pegaso', slug: 'scienze-turistiche' }
                        ]
                    },
                    9: {
                        area: 'Sportiva',
                        description: 'La tua passione è il movimento. Se ti poni un obiettivo fai di tutto per raggiungerlo e credi che il segreto per realizzare i tuoi sogni sia non arrenderti mai. Ami le sfide e credi che siano il modo migliore per migliorarsi costantemente e per tirare fuori il meglio dalle persone. Lo sport ti affascina e senti che non lo abbandonerai mai. Potresti eccellere in un corso di laurea che valorizzi la tua passione per i valori che girano attorno alle varie discipline sportive.',
                        slug: 'sportiva',
                        degrees: [
                            { title: 'Scienze Motorie', university: 'Università Pegaso', slug: 'scienze-motorie' }
                        ]
                    }
                }
            }
        },
        computed: {
            progress() {
                return (this.step / 11) * 100;
            },
            startTest() {
                return this.form.first_name && this.form.last_name && this.form.email && this.form.telephone && this.form.privacy
            },
            background() {
                if (this.step == 0) return '//testorientamento.unipegaso.it/images/intro.jpg'
                else if (this.step == 11) return '//testorientamento.unipegaso.it/images/' + 'area-' + this.result[this.result_area].slug + '.jpg'
                else return ''
            },
            courses() {
                if (this.step == 11) {
                    return this.result[this.result_area].degrees
                } 
                return []
            }
        },
        methods: {
            submitStep() {
                this.step = this.step+1
            },
            shuffle(array) {
                return array.sort((a, b) => 0.5 - Math.random());
            },
            courseImage(course) {
                if (course.university == 'Universitas Mercatorum') {
                    return '//www.unimercatorum.it/app/templates/public/default/assets/images/landing/' + course.slug + '.jpg'
                } else {
                    return '//assets.unipegaso.it/images/landing/940x500/' + course.slug + '.jpg'
                }
            },
            courseSlug(course) {
                if (course.university == 'Universitas Mercatorum') {
                    return 'https://www.unimercatorum.it/corsi-di-laurea/' + course.slug
                } else {
                    return 'https:///www.unipegaso.it/corsi-di-laurea/' + course.slug
                }
            },
            async submitForm() {
                this.step = 11
                const form = this.form
                let results = [form.a1, form.a2, form.a3, form.a4, form.a5, form.a6, form.a7, form.a8, form.a9]
                let count_a1 = results.filter(x => x == 1).length
                let count_a2 = results.filter(x => x == 2).length
                let count_a3 = results.filter(x => x == 3).length
                let count_a4 = results.filter(x => x == 4).length
                let count_a5 = results.filter(x => x == 5).length
                let count_a6 = results.filter(x => x == 6).length
                let count_a7 = results.filter(x => x == 7).length
                let count_a8 = results.filter(x => x == 8).length
                let count_a9 = results.filter(x => x == 9).length
                let count_result = [count_a1, count_a2, count_a3, count_a4, count_a5, count_a6, count_a7, count_a8, count_a9]
                let max_value = Math.max.apply(Math, count_result);
                let index_max_value = count_result.indexOf(max_value)+1
                this.result_area = index_max_value
                /*await axios.post('https://hooks.zapier.com/hooks/catch/14451976/3jttgsp/', {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length"
                    }, 
                    data: {
                        first_name: form.first_name,
                        last_name: form.last_name,
                        email: form.email,
                        telephone: form.telephone,
                        subject: 'Corsi di laurea',
                        body: 'Ho svolto il testo di orientamento online al salone dello studente ed il risultato è stato: Area ' + this.result[this.result_area].area
                    }
                })*/
                let data =  {
                        first_name: form.first_name,
                        last_name: form.last_name,
                        email: form.email,
                        telephone: form.telephone,
                        subject: 'Corsi di laurea',
                        body: 'Ho svolto il testo di orientamento online al salone dello studente ed il risultato è stato: Area ' + this.result[this.result_area].area
                }
                fetch('https://hooks.zapier.com/hooks/catch/14451976/3jttgsp/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    mode: 'no-cors',
                    body: JSON.stringify(data)
                }).then(response => {
                    console.log(1)
                })
            },
            refreshPage() {
                location.reload();
            }
        },
        mounted() {
            AOS.init();
            this.shuffle(this.questions.a1)
            this.shuffle(this.questions.a2)
            this.shuffle(this.questions.a3)
            this.shuffle(this.questions.a4)
            this.shuffle(this.questions.a5)
            this.shuffle(this.questions.a6)
            this.shuffle(this.questions.a7)
            this.shuffle(this.questions.a8)
            this.shuffle(this.questions.a9)
        }
    }
</script>

<style>
    body {
        font-family: 'Ubuntu', sans-serif;
        font-size:15px;
        background-image: url('./assets/bg-test.jpg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
    }

    .container {
        max-width:880px!important;
    }

    .progress {
        position:absolute;
        top:0;
        width:100%;
        height: 5px;
        border-radius: 0;
    }

    .box {
        background-size:cover;
        background-color:#f5a11a;
        background-repeat: no-repeat;
        
    }

    .logo-intro {
        width: 500px;
    }

    .logo-page {
        width:300px;
    }

    .lead {
        margin: 0 auto;
        padding: 45px 60px;
    }

    .btn-outline-dark {
        background-color: #fff!important;
        border-color: #fff!important;
    }

    .btn-outline-dark:hover {
        color: #2b82c5!important;
    }

    .btn-primary {
        background-color:#2b82c5!important;
    }

    .card-bg{
        background-color: #09254a;
    }

    .btn-lg{
        font-size: 1rem;
    }

    @media screen and (max-width: 576px) { 
        .lead{
            background-image: url(//testorientamento.unipegaso.it/images/box.png);
            width: 85%;
            background-repeat: no-repeat;
            padding: 30px 25px;
            font-size: 0.95rem;
        }

        .logo-intro {
            width: 85%;
        }
    }

    @media screen and (min-width: 992px ) {
        .card-bg > img {
            height: 45%;
        }

        .card-bg > .p-3 {
            height: 55%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
</style>
